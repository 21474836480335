/* Bulma Helpers */
.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: 0.75rem !important;
}

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }
  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }
  .is-size-3-mobile {
    font-size: 2rem !important;
  }
  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }
  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }
  .is-size-6-mobile {
    font-size: 1rem !important;
  }
  .is-size-7-mobile {
    font-size: 0.75rem !important;
  }
}
@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }
  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }
  .is-size-3-tablet {
    font-size: 2rem !important;
  }
  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }
  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }
  .is-size-6-tablet {
    font-size: 1rem !important;
  }
  .is-size-7-tablet {
    font-size: 0.75rem !important;
  }
}
@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }
  .is-size-2-touch {
    font-size: 2.5rem !important;
  }
  .is-size-3-touch {
    font-size: 2rem !important;
  }
  .is-size-4-touch {
    font-size: 1.5rem !important;
  }
  .is-size-5-touch {
    font-size: 1.25rem !important;
  }
  .is-size-6-touch {
    font-size: 1rem !important;
  }
  .is-size-7-touch {
    font-size: 0.75rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }
  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }
  .is-size-3-desktop {
    font-size: 2rem !important;
  }
  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }
  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }
  .is-size-6-desktop {
    font-size: 1rem !important;
  }
  .is-size-7-desktop {
    font-size: 0.75rem !important;
  }
}
@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }
  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }
  .is-size-3-widescreen {
    font-size: 2rem !important;
  }
  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }
  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }
  .is-size-6-widescreen {
    font-size: 1rem !important;
  }
  .is-size-7-widescreen {
    font-size: 0.75rem !important;
  }
}
@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }
  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }
  .is-size-3-fullhd {
    font-size: 2rem !important;
  }
  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }
  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }
  .is-size-6-fullhd {
    font-size: 1rem !important;
  }
  .is-size-7-fullhd {
    font-size: 0.75rem !important;
  }
}
.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}
@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}
@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}
@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}
@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}
@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}
@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}
@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}
@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}
@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}
@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}
@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}
@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}
@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}
@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}
@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}
@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}
@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}
@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}
@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}
@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}
.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
}

.is-family-secondary {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
}

.is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
}

.is-family-monospace {
  font-family: monospace !important;
}

.is-family-code {
  font-family: monospace !important;
}

.is-sideways-lr {
  -ms-writing-mode: sideways-lr;
      writing-mode: sideways-lr;
  -ms-writing-mode: tb-lr;
      writing-mode: vertical-lr;
}

.is-decimal {
  margin: 0 !important;
  font-weight: 100 !important;
  display: inline !important;
}

.ant-descriptions-item-label::after {
  content: "" !important;
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-table {
  background: transparent !important;
}

.navlogo {
  width: 40px;
  height: 35px;
  margin: 10px 0px 0px 20px;
}

.Divider {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: none !important;
  content: "";
}

* {
  /* scrollbar-width: thin; */
  scrollbar-width: inherit;
}
*::-webkit-scrollbar {
  offset: 0.2em;
  width: 0.5em;
  height: 0.5em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
  border: 0px !important;
  background-color: rgba(128, 128, 128, 0.5) !important;
  /* outline: 0.2px solid slategrey; */
  outline: 0px solid slategrey;
}
*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

body {
  background-color: #000000;
  background-image: linear-gradient(165deg, #000000 0%, #0D0D0D 74%);
  /* background-image: linear-gradient(165deg, #000000 0%, #444444 74%); */
}

#root {
  background: transparent; /* fallback for old browsers */
}

.content-padding-0 {
  padding: 0 !important;
}

.content-padding-05 {
  padding: 0.5rem;
}

.is-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.is-right {
  display: flex !important;
  justify-content: right;
  align-items: right;
}

.comma:not(:empty):not(:last-child):after {
  content: ", ";
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.is-footer {
  padding: 0 !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8) !important;
  /* margin-left: -1rem; */
}

.background-transparent {
  background: transparent !important;
}

.Glass {
  background-color: #202020 !important;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 0px #262311 !important;
}

#Basket-list-slot-1 .ant-select:not(.ant-select-customize-input) .ant-select-selector,
#Providers-list-slot-1 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px 0px 0px 5px !important;
  border-radius: 5px 0px 0px 5px !important;
}

#Providers-list-slot-2 .ant-select:not(.ant-select-customize-input) .ant-select-selector,
#Basket-list-slot-2 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0px 5px 5px 0px !important;
  border-radius: 0px 5px 5px 0px !important;

}
#Basket-list-slot-2 .basket-select .ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  border-radius: 5px 5px 5px 5px !important;

}

/* #Basket-list-slot-2 .ant-select:not(.ant-select-customize-input) .ant-select-selector */

.ant-form-item {
  margin: 0rem 0rem 2rem 0rem !important;
}

.ant-collapse-content {
  background-color: #202020 !important;
}

.highcharts-background {
  fill: transparent;
}

.highcharts-button-box {
  fill: transparent;
}

.highcharts-button.highcharts-button-pressed text {
  color: #feeb00 !important;
  fill: #feeb00 !important;
}
.highcharts-button.highcharts-button-pressed rect {
  fill: #595959 !important;
}
.highcharts-button.highcharts-button-normal text {
  color: #fff !important;
  fill: #fff !important;
}
.highcharts-button.highcharts-button-disabled {
  pointer-events: none;
}
.highcharts-button.highcharts-button-disabled text {
  color: #2c2c2c !important;
  fill: #2c2c2c !important;
}
.highcharts-button.highcharts-button-hover text {
  fill: #000 !important;
}
.highcharts-button.highcharts-button-hover rect {
  fill: #feeb00 !important;
}

.highcharts-grid-line {
  stroke-width: 0.3px !important;
}

.highcharts-legend-item-hidden tspan {
  fill: #484848 !important;
  font-weight: bold;
}

@-webkit-keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes closeButton {
  0% {
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes closeButton {
  0% {
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes delayShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes delayShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes apiHeadLoaderAnimation {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@keyframes apiHeadLoaderAnimation {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes userMenuOpen {
  0% {
    transform: translateY(-500px) translateX(300px) scale(0.2);
  }
  100% {
    transform: translateY(0px) translateX(0px) scale(1);
  }
}
@keyframes userMenuOpen {
  0% {
    transform: translateY(-500px) translateX(300px) scale(0.2);
  }
  100% {
    transform: translateY(0px) translateX(0px) scale(1);
  }
}
@-webkit-keyframes userMenuClose {
  0% {
    transform: translateY(0px) translateX(0px) scale(1);
  }
  100% {
    transform: translateY(-800px) translateX(300px) scale(0);
  }
}
@keyframes userMenuClose {
  0% {
    transform: translateY(0px) translateX(0px) scale(1);
  }
  100% {
    transform: translateY(-800px) translateX(300px) scale(0);
  }
}
@-webkit-keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
@-webkit-keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes slideleft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-300px);
  }
}
@keyframes slideleft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-300px);
  }
}
@-webkit-keyframes slideright {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes slideright {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-webkit-keyframes appLogoutAnimation {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  100% {
    transform: translateY(-1000px);
  }
}
@keyframes appLogoutAnimation {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  100% {
    transform: translateY(-1000px);
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes notifiactionsContentExpand {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    text-overflow: unset;
    white-space: unset;
  }
  100% {
    opacity: 1;
    text-overflow: unset;
    white-space: unset;
  }
}
@keyframes notifiactionsContentExpand {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    text-overflow: unset;
    white-space: unset;
  }
  100% {
    opacity: 1;
    text-overflow: unset;
    white-space: unset;
  }
}
@-webkit-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes BackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-card-body-0 .ant-card-body {
  padding: 0px 15px !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: rgb(66, 66, 66) !important;
}/*# sourceMappingURL=main.css.map */

.Tabletotal{
  width: 100%;
  height: 100%;
  border: solid 1px #434343;
  /* border-radius: 12px; */
  border-radius: 5px;
  margin: 0px 15px 10px 0px;
  padding: 10px 10px;
}