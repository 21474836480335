.highcharts-background {
    fill: transparent;
}
.highcharts-button-box {
    fill: transparent;
}
.highcharts-button {
    // display: none;
    // color: #fff;
    &.highcharts-button-pressed {
        & text {
            color: #feeb00 !important;
            fill: #feeb00 !important;
        }
        & rect {
            fill: #595959 !important;
        }
    }
    &.highcharts-button-normal{
        & text {
            color: #fff !important;
            fill: #fff !important;
        }
    }
    &.highcharts-button-disabled{
        pointer-events: none;
        & text {
            color: #2c2c2c !important;
            fill: #2c2c2c !important;
        }
    }
    &.highcharts-button-hover{
        & text {
            fill: #000 !important;
        }
        & rect {
            fill: #feeb00 !important;
        }
    }
}
// .highcharts-graph {
// 	filter: url(#glow);
// }
// .highcharts-range-selector-buttons{
//     & text{
//         fill: $primary !important;
//         color: $primary !important;
//     }
// }

// .highcharts-navigator-mask-inside{
//     fill: rgba(0, 70, 70, 0.2)
// }
// .highcharts-navigator-outline{
//     stroke-width: 0px !important;
// }

// .highcharts-navigator-handle{
//     &.highcharts-navigator-handle-left{
//         stroke-width: 0px !important;
//         fill: $primary !important;
//     }
//     &.highcharts-navigator-handle-right{
//         stroke-width: 0px !important;
//         fill: $primary !important;
//     }
// } 

.highcharts-grid-line{
    stroke-width: 0.3px !important;
}

.highcharts-legend-item-hidden{
    & tspan{
        fill :#484848 !important;
        font-weight: bold;
    }
}

// .highcharts-tooltip-box {
//     fill: black !important; 
//     fill-opacity: 0.6 !important;
//     stroke-width: 0 !important;
// }

// .highcharts-tooltip text {
//     fill: white !important;
//     text-shadow: 0 0 3px black !important;
// }
// .highcharts-xaxis-labels{
//     &.highcharts-navigator-xaxis{
//         & text{
//             fill: $primary !important;
//         }
//     }
//     // display: none !important;
// }

// .highcharts-bar-xaxis-color{
// 	fill: #2c5687;
// 	stroke: #2c5687;
// }
// .highcharts-bar-yaxis-color{
// 	fill: #2c5687;
// 	stroke: #2c5687;
// }

// .highcharts-data-table table {
//     border-collapse: collapse;
//     border-spacing: 0;
//     background: white;
//     min-width: 100%;
//     margin-top: 10px;
//     font-family: sans-serif;
//     font-size: 0.9em;
// }
// .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
//     border: 1px solid silver;
//     padding: 0.5em;
// }
// .highcharts-data-table tr:nth-child(even), .highcharts-data-table thead tr {
//     background: #f8f8f8;
// }
// .highcharts-data-table tr:hover {
//     background: #eff;
// }
// .highcharts-data-table caption {
//     border-bottom: none;
//     font-size: 1.1em;
//     font-weight: bold;
// }
.highcharts-range-selector-buttons>text:first-child{
    display:"Select time period";
   }