// @import url("https://fonts.googleapis.com/css?family=Muli:400,400i,700");
*{
//     margin: 0px;
//     padding: 0px;
//     box-sizing: border-box;
   //  scrollbar-color: rgba(128,128,128,.5) #000;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width:0.5em;
        height: 0.5em;
     }
     &::-webkit-scrollbar-track {
         box-shadow : inset 0 0 3px rgba(0,0,0,0.00);
        -webkit-box-shadow : inset 0 0 3px rgba(0,0,0,0.00);
     }
     &::-webkit-scrollbar-thumb {
        border: 0px !important;
        background-color: rgba(128,128,128,.5) !important;
        outline: 0.2px solid slategrey;
     }
     &::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0)
     }
    
}
    

// html{
//  background:url("assets/images/invisageBackground.jpg");  
//  background-color: #000;
//  font-size: 16px;
// }

body{
   background-color: #000000;
   background-image: linear-gradient(165deg, #000000 0%, #444444 74%);
   // background-image: url('https://unsplash.com/photos/6mwtDZUOFrw/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjQ2MzgwNjY5&force=true&w=1920');
   // background-size: cover;   
   //  background-color: #000 !important;
   //  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) !important;
	//  animation: gradient 15s ease infinite !important;
   //  margin: 0px;
   //  padding: 0px;
   //  width: 100%;
   //  overflow-x: hidden;
   //  font-family: "Muli";

   //  color: $body-color;
   //  font-size: $body-font-size;
   //  font-weight: $body-weight;
   //  line-height: $body-line-height;
    
   //  .app-content{
   //    @media (max-width: 576px) {
   //       padding:78px 10px 30px 10px;
   //       transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
   //    }
   //    @media (min-width: 576px) {
   //       padding:78px 10px 30px 65px;
   //    }
   // }    
}

#root{
   background: transparent;  /* fallback for old browsers */
   // background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
   // background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

   // background: #000000;  /* fallback for old browsers */
   // background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
   // background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
   
   // background-color: #000000;
   // background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);

   // background-color: #000000;
   // background-image: linear-gradient(60deg, #000000 0%, #414141 74%);
   
}
// */
// background-size: cover;
// -webkit-background-size: cover;
// -moz-background-size: cover;
// -o-background-size: cover;
// background-size: cover;
// background-image: linear-gradient(60deg, #000000 0%, #414141 74%);

// }

// .backgroundImage{
//    position: fixed;
//    top:0;
//    left: 0;
//    z-index: -1;
//    min-width: 100%;
//    min-height: 100%;
//    // filter: blur(5px) brightness(0.2);
//    // margin: -5px -10px -10px -5px;   

// }    

// .app{
//    margin: 1rem 4rem;
// }

// .g-glossy {
//    position: relative;
//    width: 600px;
//    height: 300px;
//    background-color: rgba(20, 20, 20, 0.6);
//    overflow: hidden;
//    z-index: 10;
   
//    &::before {
//        content: "";
//        position: absolute;
//        top: 0;
//        left: 0;
//        right: 0;
//        bottom: 0;
//       //  background-image: url($img);
//        background-repeat: no-repeat;
//        background-attachment: fixed;
//        background-size: cover;
//        filter: blur(10px);
//        z-index: -1;
//    }
// }

// .in-fullheight{
//    height: 100vh;
// }

// .worldmap{
//    position: fixed;
//    height: 100vh;
//    width: 100vw;
//    top: 70%;
//    left: 60%;
//    scale: 2;
//    z-index: -1;
// }

// .app-close{
//    animation: appLogoutAnimation 1s ease-in;
// }

// // .login-pannel{
// //    float: right;
// //    box-shadow: inset 0 0 0 200px rgba(255,255,255,0.3);
// // }

// .form-area{
//    margin:5rem
// }    
.content-padding-0{
   padding: 0 !important;  
}
.content-padding-05{
   padding: 0.5rem;  
}
// .content-padding-07{
//    padding: 0.7rem;  
// }
// .content-padding-1{
//  padding: 1rem;
// }
// .padding-left-1{
//    padding-left: 1rem;
// }
// // DIalog 
// .MuiDialog-root{
//    margin-left: 3rem;
// }
// .grid{
//    display:grid;
// }
// .flex{
//    display: flex;
// }
// .flex-wrap{
//    flex-wrap: wrap;
// }
.is-center{
   display: flex !important;
   justify-content: center;
   align-items: center;
}

.comma:not(:empty):not(:last-child):after {
   content: ", ";
}
.unselectable {
   -webkit-user-select: none;
   -webkit-touch-callout: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }

// // THis is for the noti stack 
// #notistack-snackbar{
//    text-transform: capitalize;
// }
// #BasketSelectionInput{
//    text-transform: capitalize;
// }
// .vl {
//    border-left: 1px solid $inActiveGrey;
//    height: 100vh;
//  }

//  a {
//    text-decoration: none;
//  }
 
//  a:hover {
//    color: white;
//    text-decoration: none;
//    cursor: pointer;
//  }

//  .notifications{
//    overflow-y: auto;
//    height: 100vh;
// }

// .invisage-notification{
//    display: grid;
//    padding: 1rem;
//    border-radius: 15px;
//    border-bottom: solid 2px #000;
//    border-left: solid 5px goldenrod;
//    transition: max-height 0.5s ease-in-out;
//    max-height: 100px;
//    & .wordWrap{
//        transition-delay: 1s;
//        text-overflow: ellipsis;
//        white-space: nowrap;
//    }
//    &:hover{
//        max-height: 200px;
//    & .wordWrap{
//            text-overflow: unset;
//            white-space: unset;        
//        }
//    }
// }

// .sparkline-container{
//    width: 20em;
// }
.is-footer{
   padding: 0 !important;
   width: 100%;
   position: fixed;
   bottom: 0;
   background-color:rgba(0,0,0,0.8) !important;
   margin-left: -1rem;
}

.background-transparent{
   background: transparent !important;
}

.Glass{
   background-color: #202020 !important;  
   backdrop-filter: blur(15px);
   // background-color: rgba(255, 255, 255, .15) !important;  
   // backdrop-filter: blur(15px);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
   box-shadow: 0 0 0 0px #262311 !important;
}

#Basket-list-slot-1,
#Providers-list-slot-1{
   .ant-select:not(.ant-select-customize-input){
      & .ant-select-selector{
         border-radius: 12px 0px 0px 12px !important;
      }
   }
}

#Providers-list-slot-2,
#Basket-list-slot-2{
   .ant-select:not(.ant-select-customize-input){
      & .ant-select-selector{
         border-radius: 0px 12px 12px 0px !important;
      }
   }
}
.ant-form-item{
   margin: 0rem 0rem 2rem 0rem !important;
}