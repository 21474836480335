@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes closeButton {
  0% {
    // display: none;
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes delayShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes apiHeadLoaderAnimation {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes userMenuOpen {
  0% {
    transform: translateY(-500px) translateX(300px) scale(0.2);
  }
  100% {
    transform: translateY(0px) translateX(0px) scale(1);
  }
}

@keyframes userMenuClose {
  0% {
    transform: translateY(0px) translateX(0px) scale(1);
  }
  100% {
    transform: translateY(-800px) translateX(300px) scale(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideleft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-300px);
  }
}

@keyframes slideright {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes appLogoutAnimation {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  100% {
    transform: translateY(-1000px);
  }
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

@keyframes notifiactionsContentExpand {
  0%   { 
    opacity:1;
  }
  50%  { opacity:1; 
    text-overflow: unset;
    white-space: unset;
  }
  100% { opacity:1; 
    text-overflow: unset;
    white-space: unset;
  }
}
@keyframes BackgroundAnimation {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}