.navlogo{
    width: 40px;
    height: 35px;
    margin: 10px 0px 0px 20px;
    // border-radius:7px
}

.Divider{
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid transparent;
    border-top-color: transparent;
    border-top-color: inherit;
    border-bottom: 0;
    transform: none !important;
    content: '';
}